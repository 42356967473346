<template>
  <div
    class="developer-item d-flex flex-column justify-content-between cursor-pointer"
    :class="{ 'align-items-center': isSquare }"
  >
    <square-container v-if="isSquare" class="wd-100p">
      <img
        class="developer-item__logo w-100"
        :class="{ 'p-2': addPadding }"
        :src="developer.logo"
      />
    </square-container>
    <img
      v-else
      class="developer-item__logo w-100"
      :class="{ 'p-2': addPadding }"
      :src="developer.logo"
    />

    <div class="d-flex gap-2">
      <h3 class="developer-item__name mt-3">{{ developer.name }}</h3>
      <h3 v-if="size" class="text-primary mt-3">| {{ size }}m²</h3>
    </div>
  </div>
</template>

<script>
import SquareContainer from "@/components/SquareContainer";
export default {
  name: "DeveloperItem",
  components: { SquareContainer },
  props: ["developer", "size", "isSquare", "addPadding"],
};
</script>

<style scoped lang="scss">
.developer-item {
  --color: var(--neutral-dark);

  &:hover {
    --color: var(--primary);
  }

  &__name {
    color: var(--color);
  }

  &__logo {
    object-fit: contain;
    border-radius: 0.2rem;
    overflow: hidden;
    border: 2px solid var(--primary);
    background: var(--neutral-light);
  }
}
</style>
