import { render, staticRenderFns } from "./DeveloperItem.vue?vue&type=template&id=5c61312d&scoped=true&"
import script from "./DeveloperItem.vue?vue&type=script&lang=js&"
export * from "./DeveloperItem.vue?vue&type=script&lang=js&"
import style0 from "./DeveloperItem.vue?vue&type=style&index=0&id=5c61312d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c61312d",
  null
  
)

export default component.exports