<template>
  <div class="square-container">
    <div class="square-container__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "square-container",
};
</script>

<style scoped lang="scss">
.square-container {
  position: relative;
  overflow: hidden;
  padding: 0 !important;
  height: min-content;

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  &__content > * {
    max-width: 100%;
    max-height: 100%;
  }
}

.square-container:after {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 100%;
}
</style>
