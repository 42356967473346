var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid row m-0 p-0 no-gutters"},[_c('section',{staticClass:"d-flex flex-column justify-content-start m-0 py-0 px-5 col"},[_c('div',{staticClass:"d-flex justify-content-between align-items-end mt-4 pb-3",staticStyle:{"border-bottom":"1px solid var(--primary)"}},[_c('div',{staticClass:"d-flex"},[_c('h5',{staticClass:"text-primary-light mb-0"},[_vm._v("Find your developer")]),_c('v-icon',{staticClass:"ms-2",attrs:{"color":"#000","size":"20px"}},[_vm._v(" mdi-chevron-right ")]),(_vm.project)?_c('h5',{staticClass:"text-primary-light mb-0"},[_vm._v(" "+_vm._s(_vm.project.name)+" ")]):_vm._e()],1),_c('button',{staticClass:"rounded-button px-5 border-primary"},[_c('h5',{staticClass:"text-neutral-light font-weight-400 mb-0 text-center cursor-pointer",on:{"click":function($event){return _vm.$router.push({ name: 'CreateProject1' })}}},[_vm._v(" Take me to Dashboard ")])])]),_c('h2',{staticClass:"text-neutral-dark mt-5"},[_vm._v("Please Select Your Unit Type")]),(_vm.projectId)?_c('div',{staticClass:"d-flex flex-wrap gap-5 mt-5"},_vm._l((_vm.properties),function(p){return _c('developer-item',{key:p.id,staticClass:"wd-25p h-100",attrs:{"developer":p,"size":p.size,"add-padding":true},nativeOn:{"click":function($event){return _vm.$router.push({
            name: 'PropertyPackage',
            params: {
              developerId: _vm.developerId,
              projectId: _vm.projectId,
              propertyId: p.id,
            },
          })}}})}),1):_vm._e()]),_c('section',{class:['m-0 p-0 col-3 d-none d-md-block']},[_c('img',{staticClass:"img-height img-section",attrs:{"src":require('/src/assets/images/compound-bg.jpeg'),"alt":"tech-bg"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }